
import React, { Fragment, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon, UserCircleIcon } from '@heroicons/react/outline'
import SE_LOGO from 'assets/icons/SE_LOGO.svg'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'


export default function Navbar(props) {
  const [navbarItems, setNavbarItems] = useState([["Home", "/"], ["General SAP", '/general_sap']])
  const userPermissions = useSelector(state => state.user.permissions)
  const user = useSelector(state => state.user.user)

  useEffect(() => {
    let temp_nav = []




    if (userPermissions.some(element => ['PO_STATUS','OPEN_INVOICE','VENDOR_CUSTOMER_SEARCH_HQ','CASH_ALLOCATION','GMM_VERIFICATION','OPEN_INVOICE_V2'].includes(element))){
      temp_nav.push(["AP Dashboard", '/ap_dashboard'])
    }

    if (userPermissions.some(element => ['MASTER_DATA_REPORTS','VENDOR_CUSTOMER_SEARCH_HQ','VENDOR_CUSTOMER_SEARCH_GLOBAL', 'MASS_CHECKS','MDG_E2E'].includes(element))){
      temp_nav.push(["MD Dashboard", '/md_dashboard'])
    }

    if (userPermissions.some(element => ['KSP_PSP_STRUCTURE', 'KSP_SALES_DOCUMENT_CHANGE'].includes(element))){
      temp_nav.push(["FX Dashboard", '/fx_dashboard'])
    }

    if (userPermissions.some(element => ['SATO_REPORT','WHT_REPORT'].includes(element))){
      temp_nav.push(["Tax Dashboard", '/tax_dashboard'])
    }

    if (userPermissions.some(element => ['CASH_ALERT','CRISK','BANK_LIABILITIES'].includes(element))){
      temp_nav.push(["GL Dashboard", '/gl_dashboard'])
    }

    if (userPermissions.some(element => ['CC_ORGDB','IT_COMPONENT_SCAN', 'VENDOR_VOLUME_SEARCH','HGB_REPORT', 'PARADIGM','AUDITOR_FEES','FPA_PO_STATUS'].includes(element))){
      temp_nav.push(["AC Operations", '/ac_operations'])
    }

    if (userPermissions.some(element => ['RPT'].includes(element))){
      temp_nav.push(["RPT", '/rpt'])
    }

    if (userPermissions.some(element => ['AUDIT','SEA50_UPLOAD'].includes(element))){
      temp_nav.push(["Audit", '/audit_dashboard'])
    }
    
    if (userPermissions.some(element => ['ADMIN'].includes(element))){
      temp_nav.push(["Admin", '/admin'])
    }

    setNavbarItems([...navbarItems, ...temp_nav])

  }, [userPermissions])



  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="min-w-full mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <Link to='/'>
                    <img
                      className="block lg:hidden h-8 w-24"
                      src={SE_LOGO}
                      alt="Workflow"
                    />
                    <img
                      className="hidden lg:block h-8 w-24"
                      src={SE_LOGO}
                      alt="Workflow"
                    />
                  </Link>
                </div>

                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}

                  {navbarItems.map(i => {

                    return (
                      <Link key={i[0]} to={i[1]}
                        className={props.activePage === i[1] ? 'border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium' :
                          "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                        }
                      >{i[0]}

                      </Link>
                    )
                  }

                  )}


                </div>
              </div>


              {user ?
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

                  <button
                    type="button"
                    className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>




                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span className="sr-only">Open user menu</span>
                        <UserCircleIcon
                          className="h-8 w-8 text-gray-400"
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div key={'user'} className='p-4'>
                          <b>User</b>: {user.first_name} {user.last_name}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>

                </div>

                :
                <></>
              }
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-4 space-y-1">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}


              {navbarItems.map(i => {

                return (
                  <Disclosure.Button key={i[0]}
                    className={props.activePage === i[0] ? "w-full bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium" :
                      "w-full border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"

                    }
                  >

                    <Link to={i[1]}
                    >{i[0]}</Link>

                  </Disclosure.Button>
                )
              })}



            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
